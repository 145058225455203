import React, { FC } from 'react';
import { pxToRem } from '~/utils';

type Props = {
  color?: string;
  onPress?: () => void;
  size?: number;
};

const Icon: FC<Props> = ({ color = '#4E89FD', onPress, size = 26 }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 500 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      cursor: 'pointer',
      marginLeft: pxToRem(size),
    }}
    onClick={onPress}
  >
    <path
      d="M250 39.0625C208.28 39.0625 167.498 51.4338 132.809 74.6119C98.1209 97.79 71.0845 130.734 55.1191 169.278C39.1538 207.821 34.9765 250.234 43.1156 291.152C51.2546 332.07 71.3445 369.655 100.845 399.155C130.345 428.655 167.93 448.745 208.848 456.884C249.766 465.023 292.178 460.846 330.722 444.881C369.266 428.915 402.21 401.879 425.388 367.191C448.566 332.502 460.937 291.719 460.937 250C460.875 194.075 438.632 140.458 399.087 100.913C359.542 61.3682 305.925 39.1245 250 39.0625ZM250 414.062C217.551 414.062 185.832 404.44 158.852 386.413C131.872 368.386 110.843 342.762 98.426 312.784C86.0085 282.805 82.7595 249.818 89.0899 217.993C95.4203 186.168 111.046 156.935 133.99 133.99C156.935 111.046 186.168 95.4203 217.993 89.0899C249.818 82.7595 282.805 86.0085 312.784 98.426C342.762 110.844 368.386 131.872 386.413 158.852C404.44 185.832 414.062 217.551 414.062 250C414.016 293.498 396.716 335.201 365.958 365.958C335.201 396.716 293.498 414.016 250 414.062ZM328.125 250C328.124 253.768 327.215 257.481 325.474 260.823C323.734 264.165 321.212 267.039 318.125 269.199L240 323.887C236.488 326.344 232.369 327.792 228.091 328.071C223.813 328.351 219.541 327.452 215.739 325.472C211.936 323.493 208.75 320.508 206.526 316.843C204.302 313.179 203.126 308.974 203.125 304.687V195.312C203.126 191.026 204.302 186.821 206.526 183.157C208.75 179.492 211.936 176.507 215.739 174.528C219.541 172.548 223.813 171.649 228.091 171.929C232.369 172.208 236.488 173.656 240 176.113L318.125 230.801C321.212 232.961 323.734 235.834 325.474 239.177C327.215 242.519 328.124 246.232 328.125 250Z"
      fill={color}
    />
  </svg>
);

export default Icon;

import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import ReactLoading from 'react-loading';
import { Play } from '~/assets/svg';
import { Collapse, Header, If } from '~/components';
import { colors } from '~/theme';
import { useStores, useFormikContext } from '~/utils';
import {
  AlignFields,
  Background,
  Container,
  SectionFields,
  WrapperFields,
  TextEmpty,
  CustomTextField,
} from './styles';

type Props = {};

const Tests: FC<Props> = (): JSX.Element => {
  const { values, handleChange, isSubmitting, submitForm, setFieldValue } =
    useFormikContext<TestsType.Forms>();
    const { caseTest } = useStores()

  return (
    <Background>
      <Header title='Test CaseGen'/>
      <Container>
        <SectionFields>
          <AlignFields>
            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Sistema / Aplicação *"
                variant="outlined"
                sx={{ m: 1 }}
                name="system"
                onChange={handleChange('system')}
                disabled={isSubmitting}
                value={values.system}
              />
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Épico"
                variant="outlined"
                sx={{ m: 1 }}
                name="epic"
                onChange={handleChange('epic')}
                disabled={isSubmitting}
                value={values.epic}
              />
            </WrapperFields>

            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Funcionalidade *"
                variant="outlined"
                sx={{ m: 1 }}
                name="resource"
                onChange={handleChange('resource')}
                disabled={isSubmitting}
                value={values.resource}
              />

              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Regras de negócio"
                variant="outlined"
                sx={{ m: 1 }}
                name="rules"
                onChange={handleChange('rules')}
                disabled={isSubmitting}
                value={values.rules}
              />
            </WrapperFields>

            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Estória de usuário"
                variant="outlined"
                sx={{ m: 1 }}
                name="estoryUser"
                onChange={handleChange('estoryUser')}
                disabled={isSubmitting}
                value={values.estoryUser}
                maxRows={3}
                minRows={3}
              />
            </WrapperFields>

            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label={
                  values.estoryUser
                    ? 'Critérios de aceite *'
                    : 'Critérios de aceitação *'
                }
                variant="outlined"
                sx={{ m: 1 }}
                multiline
                maxRows={3}
                minRows={3}
                name="acceptanceCriteria"
                onChange={handleChange('acceptanceCriteria')}
                disabled={isSubmitting}
                value={values.acceptanceCriteria}
              />

              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Critérios de negação"
                variant="outlined"
                sx={{ m: 1 }}
                multiline
                maxRows={3}
                minRows={3}
                name="denialCriteria"
                onChange={handleChange('denialCriteria')}
                disabled={isSubmitting}
                value={values.denialCriteria}
              />
            </WrapperFields>

            <CustomTextField
              style={{ width: '49%' }}
              id="outlined-basic"
              label="Quantidade de casos de testes"
              variant="outlined"
              type="number"
              sx={{ m: 1 }}
              name="amountTests"
              disabled={isSubmitting}
              value={values.amountTests}
              InputProps={{ inputProps: { min: 1, max: 30, step: 1 } }}
              onChange={({ target }) => {
                if (+target.value > 30) return;
                setFieldValue('amountTests', target.value);
              }}
            />
          </AlignFields>

          <If condition={!isSubmitting}>
            <Play onPress={submitForm} color={colors.primary.main} />
          </If>

          <If condition={isSubmitting}>
            <ReactLoading width={'5%'} type="bubbles" color={colors.primary.main} />
          </If>
        </SectionFields>

        {!caseTest.content.length ? (
          <TextEmpty>Nenhuma informação até o momento.</TextEmpty>
        ) : (
          <div>
            {/* Renderize aqui o conteúdo desejado quando response estiver disponível */}
            <Collapse
              content={caseTest.content}
              title={caseTest.title}
              description={caseTest.description}
            />
          </div>
        )}
      </Container>
    </Background>
  );
};

export default Tests;

import React, { FC } from 'react';
import { Doc, Epic, History, Resource, Tests } from '~/assets/svg';
import Arrow from '~/assets/svg/Arrow';
import { colors } from '~/theme';
import { Content, Image, Title } from './styles';

type Props = {
  title: string;
  icon: 'epic' | 'resource' | 'history' | 'doc' | 'tests';
  onPress: () => void;
  disabled?: boolean;
};

const icons = {
  epic: Epic,
  resource: Resource,
  history: History,
  doc: Doc,
  tests: Tests,
};

const color = {
  epic: colors.primary.main,
  resource: colors.secondary.main,
  history: colors.tertiary.main,
  doc: colors.accent.main,
  tests: colors.secondary.dark,
};

const CardResource: FC<Props> = ({ icon = 'epic', onPress, title, disabled }) => (
  <Content onClick={onPress} style={{ backgroundColor: disabled ? 'gray' : undefined }}>
    <Image src={icons[icon]} style={{ filter: disabled ? 'grayscale(100%)' : undefined }} />
    <Title>{title}</Title>

    <Arrow standard color={color[icon]} style={{ filter: disabled ? 'grayscale(100%)' : undefined }}/>
  </Content>
);

export default CardResource;

import React, { FC } from 'react';
import ReactLoading from 'react-loading';
import { Play } from '~/assets/svg';
import { Collapse, Header, If } from '~/components';
import { colors } from '~/theme';
import { useFormikContext, useStores } from '~/utils';
import {
  AlignFields,
  Background,
  Container,
  SectionFields,
  TextEmpty,
  WrapperFields,
  CustomTextField
} from './styles';

type Props = {};

const Epic: FC<Props> = (): JSX.Element => {
  const { values, handleChange, isSubmitting, submitForm } =
    useFormikContext<EpicType.Forms>();
    const { epic } = useStores()

  return (
    <Background>
      <Header title='EpicGen'/>
      <Container>
        <SectionFields>
          <AlignFields>
            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Sistema / Aplicação"
                variant="outlined"
                sx={{ m: 1 }}
                name="system"
                disabled={isSubmitting}
                onChange={handleChange('system')}
                value={values.system}
              />

              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Objetivo do Sistema / Aplicação"
                variant="outlined"
                sx={{ m: 1 }}
                name="objective"
                disabled={isSubmitting}
                onChange={handleChange('objective')}
                value={values.objective}
              />
            </WrapperFields>
          </AlignFields>

          <If condition={!isSubmitting}>
            <Play onPress={submitForm} color={colors.primary.main} />
          </If>

          <If condition={isSubmitting}>
            <ReactLoading type="bubbles" color={colors.primary.main} />
          </If>
        </SectionFields>

        {!epic.content.length ? (
          <TextEmpty>Nenhuma informação até o momento.</TextEmpty>
        ) : (
          <div>
            {/* Renderize aqui o conteúdo desejado quando response estiver disponível */}
            <Collapse
              content={epic.content}
              title={epic.title}
              description={epic.description}
            />
          </div>
        )}
      </Container>
    </Background>
  );
};

export default Epic;

import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ReactLoading from 'react-loading';
import TextField from '@material-ui/core/TextField';
import { Play } from '~/assets/svg';
import { Collapse, Header, If } from '~/components';
import { colors } from '~/theme';
import { useFormikContext, useStores  } from '~/utils';
import {
  AlignFields,
  Background,
  Container,
  SectionFields,
  TextEmpty,
  WrapperFields,
  CustomTextField
} from './styles';

type Props = {
};

const Docs: FC<Props> = (): JSX.Element => {
  const { values, handleChange, isSubmitting, submitForm } =
    useFormikContext<DocType.Forms>();
    const { documentation } = useStores();
  return (
    <Background>
        <Header title='Code DocGen' />
        <Container>
        <SectionFields>
          <AlignFields>
            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Código"
                variant="outlined"
                sx={{ m: 1 }}
                multiline
                minRows={15}
                name="code"
                onChange={handleChange('code')}
                disabled={isSubmitting}
                value={values.code}
              />
            </WrapperFields>
          </AlignFields>

          <If condition={!isSubmitting}>
            <Play onPress={submitForm} color={colors.primary.main} />
          </If>

          <If condition={isSubmitting}>
            <ReactLoading width={'5%'}type="bubbles" color={colors.primary.main} />
          </If>
        </SectionFields>

        {!documentation.content.length ? (
          <TextEmpty>Nenhuma informação até o momento.</TextEmpty>
        ) : (
          <div>
            {/* Renderize aqui o conteúdo desejado quando response estiver disponível */}
            <Collapse
              content={documentation.content}
              title={documentation.title}
              description={documentation.description}
            />
          </div>
        )}
      </Container>
    </Background>
  );
};

export default observer(Docs);

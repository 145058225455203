import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Formik, useStores } from '~/utils';
import Resource from './Doc';

type Props = {
  response?: TestsType.Response[];
};

const DocContainer: FC<Props> = () => {
  const { documentation } = useStores();

  const onSubmit = async (data: DocType.Forms, { setSubmitting }: any) => {
    setSubmitting(true);

    await documentation.onDocumentation(data);

    setTimeout(() => {
      setSubmitting(false);
    }, 60000);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        code: '',
      }}
    >
      <Resource />
    </Formik>
  );
};

export default observer(DocContainer);

import React, { FC, useEffect, useState } from 'react';
import { Menu, MenuItem, Toolbar } from '@material-ui/core';
import { PrimeLogon } from '~/assets/img';
import { Path } from '~/routes/routes.path';
import { Expand } from '~/assets/svg';
import { device } from '~/theme/breakpoints';
import { useStores } from '~/utils';
import If from '../If';
import { observer } from 'mobx-react';
import { Amplify } from '~/services';
import {
  Content,
  SectionIcon,
  ImgIcon,
  SectionLogo,
  SectionToolbar,
  NameUser,
  TextProduct,
} from './styles';

type Props = {
  title: string;
};

const amplify = new Amplify();

const Header: FC<Props> = ({ title }) => {
  const { routing } = useStores();
  const [toolbarOpened, setToolbar] = useState(false);
  const [username, setUsername] = useState('');


  useEffect(() => {
    const getUsername = async () => {
      const { username } = await amplify.getUsername();
      setUsername(username);
    };
    getUsername();
  }, [username]);

  const handleToolbar = () => {
    setToolbar(!toolbarOpened);
  };

  const onLogout = async () => {
    await amplify.logout();
    routing.replace(Path.LOGIN);
  };

  const onHome = () => {
    routing.replace(Path.HOME);
  };

  return (
    <Content>
      <SectionLogo>
        <ImgIcon src={PrimeLogon} title='Home' onClick={onHome}/>
        <TextProduct>{title}</TextProduct>
      </SectionLogo>
      <SectionToolbar>
        <SectionIcon onClick={handleToolbar}>
          <If condition={!device.isMobile}>
            <NameUser>{username}</NameUser>
          </If>
          <Expand />
        </SectionIcon>

        <If condition={toolbarOpened}>
          <Toolbar onClick={handleToolbar}>
            <Menu
              open={toolbarOpened}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              style={{ marginTop: 50, width: 200 }}
            >
              <MenuItem style={{ width: 150 }} onClick={onLogout}>
                Sair
              </MenuItem>
            </Menu>
          </Toolbar>
        </If>
      </SectionToolbar>
    </Content>
  );
};

export default observer(Header);

import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { GeneratorAPI } from '~/services/api';

export default class EpicStore {
  @observable
  data: EpicType.Response[] = [];

  @observable
  title: string = '';

  @observable
  description: string = '';

  @observable
  content: any = [];

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'EpicStore',
      properties: [],
      storage: window.localStorage,
    });
  }

  @action
  onEpic = async (data: EpicType.Forms): Promise<void> => {
    this.data = [];

    const rsp = await GeneratorAPI.onEpic(data);
    this.title = rsp[0].title;
    this.description = rsp[0].description;
    this.content = rsp[0].content;
  };
}

import { getTheme, pxToRem, styled } from '~/utils';

// Colors
const primaryLight = getTheme('primary.light');
const primaryDark = getTheme('primary.dark');

// Radius
const mediumRadius = getTheme('mediumRadius');

// Spacing
const smallSpacing = getTheme('smallSpacing');
const mediumSpacing = getTheme('mediumSpacing');

// Breakpoints
const inMobileAndTablet = getTheme('inMobileAndTablet');

export const Content = styled.button`
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${primaryLight};

  width: ${pxToRem(400)};
  height: ${pxToRem(70)};
  border-radius: ${mediumRadius};
  padding: ${smallSpacing};
  margin-top: ${mediumSpacing};

  box-shadow: 0px 0px 5px 0px ${primaryDark}50;

  @media ${inMobileAndTablet} {
    width: 90%;
  }
`;

export const Image = styled.img`
  width: ${pxToRem(50)};
`;

export const Title = styled.h3`
  width: 80%;
  text-align: left;
  padding: ${smallSpacing};
  font-size: ${pxToRem(18)};
  font-weight: 600;
  color: ${primaryDark};
`;

import React, { FC, useState } from 'react';
import ReactLoading from 'react-loading';
import { Play } from '~/assets/svg';
import { Collapse, Header, If } from '~/components';
import { colors } from '~/theme';
import { useFormikContext, useStores } from '~/utils';
import {
  AlignFields,
  Background,
  Container,
  SectionFields,
  WrapperFields,
  TextEmpty,
  CustomTextField,
  CustomFormControl,
  CustomInputLabel,
  CustomSelect,
  CustomMenuItem
} from './styles';

type Props = {};

const Story: FC = (): JSX.Element => {
  const { values, handleChange, isSubmitting, submitForm } =
    useFormikContext<StoryType.Forms>();
    const { story } = useStores()

  return (
    <Background>
      <Header title='StoryGen'/>
      <Container>
        <SectionFields>
          <AlignFields>
            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Épico"
                variant="outlined"
                sx={{ m: 1 }}
                name="epic"
                onChange={handleChange('epic')}
                disabled={isSubmitting}
                value={values.epic}
              />

              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Descrição / Objetivo do Sistema"
                variant="outlined"
                sx={{ m: 1 }}
                name="objective"
                disabled={isSubmitting}
                onChange={handleChange('objective')}
                value={values.objective}
              />
            </WrapperFields>

            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Regras de Negócio"
                variant="outlined"
                sx={{ m: 1 }}
                multiline
                minRows={5}
                name="rules"
                onChange={handleChange('rules')}
                disabled={isSubmitting}
                value={values.rules}
              />

              <CustomFormControl 
                fullWidth
                variant="outlined"
                sx={{ m: 1 }}
                disabled={isSubmitting}
              >
                <CustomInputLabel id="demo-simple-select-label">Prioridade</CustomInputLabel>
                <CustomSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='priority'
                  onChange={handleChange}
                  value={values.priority}
                >
                  <CustomMenuItem value={'baixa'}>Baixa</CustomMenuItem>
                  <CustomMenuItem value={'media'}>Média</CustomMenuItem>
                  <CustomMenuItem value={'alta'}>Alta</CustomMenuItem>
                  <CustomMenuItem value={'critica'}>Crítica</CustomMenuItem>
                </CustomSelect>
              </CustomFormControl>
            </WrapperFields>
          </AlignFields>

          <If condition={!isSubmitting}>
            <Play onPress={submitForm} color={colors.primary.main} />
          </If>

          <If condition={isSubmitting}>
            <ReactLoading width={'5%'} type="bubbles" color={colors.primary.main} />
          </If>
        </SectionFields>

        {!story.content.length ? (
          <TextEmpty>Nenhuma informação até o momento.</TextEmpty>
        ) : (
          <div>
            {/* Renderize aqui o conteúdo desejado quando response estiver disponível */}
            <Collapse
              content={story.content}
              title={story.title}
              description={story.description}
            />
          </div>
        )}
      </Container>
    </Background>
  );
};

export default Story;

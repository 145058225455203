import { Request } from '~/services';

export const onCaseTest = async ({
  system,
  epic,
  resource,
  rules,
  estoryUser,
  acceptanceCriteria,
  denialCriteria,
  amountTests,
}: TestsType.Forms): Promise<TestsType.Response[]> => {
  try {
    const { data } = await Request.post('/test-case', {
      sistema: system,
      epico: epic,
      funcionalidade: resource,
      regras_negocio: rules,
      estoria_usuario: estoryUser,
      criterios_aceite: acceptanceCriteria,
      criterios_negacao: denialCriteria,
      qtd_casos_testes: amountTests,
    });

    return typeof data === 'object' ? [data] : data;
    
  } catch (error: any) {
    return [{
      title: 'Erro na requisição',
      description: error?.response.data,
      content: [
        {
          title: '',
          Value: '',
          description: '',
          items: [
            {
              title: '',
              items: [''],
            },
          ],
        },
      ],
    }];
  }
};

export const onStory = async ({
  epic,
  objective,
  rules,
  priority,
}: StoryType.Forms): Promise<StoryType.Response[]> => {
  try {
    const { data } = await Request.post('/story', {
      epic: epic,
      objective: objective,
      rules: rules,
      priority: priority,
    });

    return typeof data === 'object' ? [data] : data;
  } catch ( error: any ) {
    return [{
      title: 'Erro na requisição',
      description: error?.response.data,
      content: [
        {
          title: '',
          Value: '',
          description: '',
          items: [
            {
              title: '',
              items: [''],
            },
          ],
        },
      ],
    }];
  }
};

export const onEpic = async ({
  objective,
  system,
}: EpicType.Forms): Promise<EpicType.Response[]> => {
  try {
    const { data } = await Request.post('/epic', {
      objetivo_aplicacao: objective,
      aplicacao: system,
    });

    return typeof data === 'object' ? [data] : data;
  } catch ( error: any ) {
    return [{
      title: 'Erro na requisição',
      description: error?.response.data,
      content: [
        {
          title: '',
          Value: '',
          description: '',
          items: [
            {
              title: '',
              items: [''],
            },
          ],
        },
      ],
    }];
  }
};

export const onDocumentation = async ({
  code,
}: DocType.Forms): Promise<DocType.Response[]> => {
  try {
    const { data } = await Request.post('/documentation', {
      codigo: code,
    });

    return typeof data === 'object' ? [data] : data;
  } catch (error) {
    return [{
      title: 'Erro na requisição',
      description: 'Erro ao tentar enviar os dados para o servidor.',
      content: [
        {
          title: '',
          Value: '',
          description: '',
          items: [
            {
              title: '',
              items: [''],
            },
          ],
        },
      ],
    }];
  }
};

import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, useStores } from '~/utils';
import Resource from './Tests';

type Props = {
  response?: TestsType.Response[];
};

const TestsContainer: FC<Props> = () => {
  const { caseTest } = useStores();

  const onSubmit = async (data: TestsType.Forms, { setSubmitting }: any) => {
    setSubmitting(true);

    await caseTest.onCaseTest(data);

    setTimeout(() => {
      setSubmitting(false);
    }, 60000);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        system: '',
        objectiveSystem: '',
        epic: '',
        objectiveEpic: '',
        resource: '',
        rules: '',
        estoryUser: '',
        acceptanceCriteria: '',
        denialCriteria: '',
        amountTests: 1,
      }}
    >
      <Resource />
    </Formik>
  );
};

export default observer(TestsContainer);

import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Formik, useStores } from '~/utils';
import Epic from './Epic';

type Props = {
  response?: EpicType.Response[];
};

const EpicContainer: FC<Props> = () => {
  const { epic } = useStores();

  const onSubmit = async (data: EpicType.Forms, { setSubmitting }: any) => {
    setSubmitting(true);

    await epic.onEpic(data);

    setTimeout(() => {
      setSubmitting(false);
    }, 60000);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        system: '',
        objective: '',
        unity_business: '',
      }}
    >
      <Epic />
    </Formik>
  );
};

export default observer(EpicContainer);

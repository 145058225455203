import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Doc, Epic, Story, Resource, Tests } from '~/scenes';
import PrivateRoute from './private.route';
import RouterProvider from './provider';
import { Path } from './routes.path';
import { REACT_APP_PRIME_SSO_URL } from '~/utils';

const SSO_LOGIN_URL = REACT_APP_PRIME_SSO_URL;

const NavigationApp: FC = () => {
  const LoginRedirect = () => {
    // Configurar página de erro se variável não estiver configurada
    if (SSO_LOGIN_URL != undefined) {
      window.location.href = SSO_LOGIN_URL;
      return null;
    }
  };

  return (
    <Routes>
      <Route path={Path.HOME} Component={LoginRedirect} />
      <Route path={Path.EPIC} element={<PrivateRoute render={<Epic />} />} />
      <Route
        path={Path.RESOURCE}
        element={<PrivateRoute render={<Resource />} />}
      />
      <Route
        path={Path.STORY}
        element={<PrivateRoute render={<Story />} />}
      />
      <Route path={Path.TESTS} element={<PrivateRoute render={<Tests />} />} />
      <Route path={Path.LOGIN} Component={LoginRedirect} />
    </Routes>
  );
};

export { RouterProvider };

export default NavigationApp;
